import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ProtectedComponent } from 'components'
import { Column, Typography, Row, Input, Icon, Image } from 'design-system'
import TicketCard from '../TicketCard'
import TicketTabItem from './TicketTabItem'
import PopoverTicketsFilter from '../PopoverTicketsFilter'
import ModalCreateTicket from '../ModalCreateTicket'

import { useAuth, useClickout, useDebounce, useTickets } from 'hooks'

import { robotTagsBW } from 'assets/ilustrations'

import { showTicketList } from 'services'

import { notify } from 'helpers'
import SkeletonTicketCard from './SkeletonTicketCard'
import { actionUnreadTicketsTypes } from 'providers/TicketContext/store'

const TicketList = () => {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const [search, setSearch] = useState('')
  const [userFilter, setUserFilter] = useState('all')
  const [otherFilters, setOtherFilters] = useState('')
  const [departmentsFilter, setDepartmentsFilter] = useState([])
  const [attendantFilter, setAttendantFilter] = useState([])

  const location = useLocation()

  const { triggerRef, elementRef, openedState } = useClickout(false)

  const {
    userData: { company_user },
  } = useAuth()

  const debounceSearch = useDebounce(search, 1500)
  const debounceDepartment = useDebounce(departmentsFilter, 500)
  const debounceAttendant = useDebounce(attendantFilter, 500)
  const debounceUser = useDebounce(userFilter, 500)
  const debounceOther = useDebounce(otherFilters, 500)

  const { state, dispatch, actionTicketTypes, actionCurrentStatusTypes } = useTickets()

  const statusListDict = {
    attending: 'pending,attending',
    closed: 'closed',
  }

  const filterDict = {
    attendant: {
      key: 'last_message_sender_type',
      value: 'attendant',
    },
    contact: {
      key: 'last_message_sender_type',
      value: 'contact',
    },
  }

  const fetchTickets = async (params) => {
    try {
      setLoading(true)
      if (location?.state?.status) {
        dispatch({ type: actionCurrentStatusTypes.SELECT_STATUS, payload: location?.state?.status })
        location.state.status = null
      }

      const { data } = await showTicketList({ page_size: 1000, ...params })

      if (state.current_status === 'attending') {
        dispatch({
          type: actionUnreadTicketsTypes.LOAD_UNREAD_TICKETS_LIST,
          payload: data.results.filter(
            (t) =>
              t.last_message.sender_type === 'contact' &&
              (t.status === 'pending' || t.status === 'attending'),
          ),
        })
      }

      dispatch({
        type: actionTicketTypes.LOAD_TICKETS_LIST,
        payload: data.results,
      })
    } catch {
      notify.error('Não foi possível resgatar listagem de mensagens.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTickets({
      status__in: statusListDict[location?.state?.status] || statusListDict[state.current_status],
      search: debounceSearch,
      ordering: '-created_at',
      ...(debounceUser === 'attendant' ? { attendant: company_user?.id } : ''),
      ...(debounceOther
        ? { [filterDict[debounceOther]?.key]: `${filterDict[debounceOther]?.value}` }
        : ''),
      ...(debounceDepartment.length > 0
        ? { department__in: debounceDepartment.map((i) => i.id).join(',') }
        : ''),
      ...(debounceAttendant.length > 0
        ? { attendant__in: debounceAttendant.map((i) => i.id).join(',') }
        : ''),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.current_status,
    debounceSearch,
    debounceDepartment,
    debounceAttendant,
    debounceUser,
    debounceOther,
  ])

  const handleChangeTab = (status) => {
    setSearch('')
    return dispatch({ type: actionCurrentStatusTypes.SELECT_STATUS, payload: status })
  }

  return (
    <Column
      width='100%'
      maxWidth={['100%', '100%', '400px']}
      overflow='auto'
      border='1px solid #E8E8E8'
      background='#fff'
      p='16px'
    >
      <Typography fontSize='18px' fontWeight='600'>
        Conversas
      </Typography>
      <Row alignItems='center' mt='16px' gap='16px'>
        <Input
          width='100%'
          placeholder='Pesquisar'
          type='search'
          value={search}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
          icon='Search'
          iconPosition='left'
          iconProps={{ color: 'grey.100' }}
        />
        <Row position='relative'>
          <Icon
            ref={triggerRef}
            icon='Filter2'
            color={openedState ? 'grey.300' : 'grey.200'}
            cursor='pointer'
            size='lg'
          />
          {openedState && (
            <PopoverTicketsFilter
              userFilter={userFilter}
              setUserFilter={setUserFilter}
              departmentsFilter={departmentsFilter}
              setDepartmentsFilter={setDepartmentsFilter}
              attendantFilter={attendantFilter}
              setAttendantFilter={setAttendantFilter}
              setOtherFilters={setOtherFilters}
              otherFilters={otherFilters}
              elementRef={elementRef}
              open={openedState}
            />
          )}
        </Row>
        <ProtectedComponent allowedRoles={['manage_tickets']} unauthorizedComponent={false}>
          <Icon
            icon='Edit2'
            size='lg'
            color='grey.200'
            onClick={() => setOpen(!open)}
            cursor='pointer'
          />
        </ProtectedComponent>
      </Row>
      <Row mb='18px' mt='12px'>
        <TicketTabItem
          active={state.current_status === 'attending'}
          label='Em atendimento'
          onClick={() => handleChangeTab('attending')}
          count={state?.unread_tickets_list?.length}
        />
        <TicketTabItem
          active={state.current_status === 'closed'}
          label='Encerradas'
          onClick={() => handleChangeTab('closed')}
        />
      </Row>
      {loading ? (
        <SkeletonTicketCard />
      ) : (
        <Column mt='6px' pb='24px' pr='10px' overflow='auto'>
          {state?.tickets_list?.length > 0 ? (
            state?.tickets_list?.map((ticket) => (
              <TicketCard key={`${ticket?.id}_${ticket?.connection?.id}`} ticket={ticket} />
            ))
          ) : (
            <Column mt='50px' alignItems='center' justifyContent='center'>
              <Image width='110px' src={robotTagsBW} />
              <Typography mt='24px'>Sem conversas por aqui</Typography>
            </Column>
          )}
        </Column>
      )}
      {open && (
        <ModalCreateTicket
          info={open}
          dispatch={dispatch}
          actionTypes={actionTicketTypes}
          actionCurrentStatusTypes={actionCurrentStatusTypes}
          onClose={() => setOpen(null)}
        />
      )}
    </Column>
  )
}

export default TicketList
